import { useTranslationsContextData } from '../../../hooks/use-context-data';
import cx from 'classnames';
import React, { useMemo } from 'react';
import { ModalTypeProps } from '../../../types/modal';
import { PaymentProps, Price, PricingTeam } from '../../../types/payment';
import { PricingButton } from './pricing-button';
interface PricingButtonProps {
  text: string;
  url?: string;
  modalType: ModalTypeProps;
}

export type PricingType = 'default' | 'pro-bono' | 'prescription';

export interface PricingBoxProps {
  type: PricingType;
  priceText: string;
  price?: Price;
  pricingTeam?: PricingTeam[];
  buttonPrimary: PricingButtonProps;
  buttonSecondary?: PricingButtonProps;
  additionalModal?: PricingButtonProps;
  paymentProps?: PaymentProps;
  chosenMember?: PaymentProps['pricingTeam']['0'];
}

export const PricingBox: React.FC<PricingBoxProps> = ({
  type,
  priceText,
  price,
  buttonPrimary,
  buttonSecondary,
  additionalModal,
  paymentProps,
  chosenMember,
}) => {
  const translations = useTranslationsContextData();
  const priceValue = useMemo(() => {
    if (!paymentProps.pricingTeam?.length) {
      if (!price?.value) return null;
      return price.value;
    }
    if (chosenMember) {
      return chosenMember.price.value;
    }
    return Math.min(
      ...paymentProps.pricingTeam.map((elem) => Number(elem.price.value))
    );
  }, [chosenMember, paymentProps.pricingTeam]);

  const currency = useMemo(() => {
    if (!paymentProps.pricingTeam?.length) {
      if (!price?.currency) return null;
      return price.currency;
    }
    if (chosenMember) {
      return chosenMember.price.currency;
    }
    const minimalPrice = paymentProps.pricingTeam.reduce(
      (result, elem) => (result.price.value > elem.price.value ? elem : result),
      paymentProps.pricingTeam[0]
    );
    return minimalPrice.price.currency;
  }, [chosenMember, paymentProps.pricingTeam]);

  return (
    <div
      className={cx(
        'py-14 px-11 text-center lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12 lg:max-w-84 lg:w-3/10',
        {
          'text-gray-900 bg-gray-50': type !== 'prescription',
          'text-white bg-green': type === 'prescription',
        }
      )}
    >
      <p className="body-s">{priceText}</p>
      <div className="mt-4 flex items-center justify-center">
        {!chosenMember && !!paymentProps.pricingTeam?.length && (
          <span className="text-xs mr-2 mt-4">
            {translations['price.from'] || 'od'}
          </span>
        )}
        <span
          className={cx({
            'h300-normal': Boolean(currency),
            'h400 font-semibold': !currency || priceValue.toString().length > 5,
          })}
        >
          {priceValue}
        </span>
        {currency && (
          <span
            className={cx('ml-3 body-m', {
              'text-gray-500': type !== 'prescription',
              'text-white': type === 'prescription',
            })}
          >
            {' '}
            {currency}
          </span>
        )}
      </div>
      <PricingButton
        className="mt-4"
        buttonType="primary"
        pricingType={type}
        chosenMember={chosenMember}
        {...buttonPrimary}
        {...(chosenMember?.teamMember
          ? {
              url: chosenMember?.teamMember?.appointment?.url,
            }
          : {})}
      />
      {buttonSecondary && (
        <PricingButton
          className="mt-6"
          buttonType="secondary"
          pricingType={type}
          paymentProps={{
            ...paymentProps,
            price: { value: priceValue, currency },
          }}
          chosenMember={chosenMember}
          {...buttonSecondary}
        />
      )}
      {additionalModal ? (
        <PricingButton
          className="mt-4"
          buttonType="additional"
          pricingType={type}
          chosenMember={chosenMember}
          {...additionalModal}
        />
      ) : null}
    </div>
  );
};
