import { GridBlocks, GridBlocksProps } from '../grid-blocks/grid-blocks';
import { Heading, HeadingProps } from '../heading/heading';

export interface GalleryProps {
  id?: string;
  heading?: HeadingProps;
  photos: GridBlocksProps['blocks'];
}

export const Gallery = ({ id, heading, photos }) => {
  return (
    <section id={id} className="bg-gray-200 py-16 lg:py-28">
      {heading && (
        <Heading
          className="max-w-8xl mx-auto px-6 lg:px-8 pb-8"
          tag="h2"
          {...heading}
        />
      )}
      <div className="max-w-8xl mx-auto px-6 lg:px-8">
        <GridBlocks blocks={photos} type="gallery" />
      </div>
    </section>
  );
};