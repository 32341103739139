import React, { Context, ReactNode } from 'react';
import '../../style.css';
import { MenuProps } from '../../types/menu';
import { ModalTypeProps } from '../../types/modal';
import {
  SettingsProps,
  SocialLinksProps,
  TranslationProps,
} from '../../types/shared';
import { parseTranslations } from '../../utils/parse-translations';
import AnnouncementBar, {
  AnnouncementBarProps,
} from '../announcement-bar/announcement-bar';
import { CookieConsentComponent } from '../cookie-consent/cookie-consent-component';
import Footer, { FooterProps } from '../footer/footer';
import { FooterContactProps } from '../footer/footer-contact';
import Menu from '../menu/menu';
import SEO, { SEOProps } from '../seo/seo';
import { parseLocalizationUrls } from '../../utils/parse-localization-url';
import CanonicalLink from '../seo-links/canonical-link';
import { useIsMobile } from '../../hooks/use-is-mobile';
import HreflangLink from '../seo-links/hreflang-link';

interface DataProps<T> {
  children?: ReactNode;
  menu: MenuProps;
  contactData: { items: FooterContactProps[] };
  footer: FooterProps;
  socialLinks: SocialLinksProps;
  modals: { nodes: ModalTypeProps[] };
  translations?: { items: TranslationProps[] };
  seo?: SEOProps;
  announcementBar?: AnnouncementBarProps;
  settings?: SettingsProps;
  localizations?: {
    data: {
      attributes: T;
    }[];
    current: T;
    getLocalizationUrl: (attr: T) => string;
  };
}
export interface DataContextProps {
  modals?: ModalTypeProps[];
  translations?: TranslationProps[];
  settings?: SettingsProps;
  localizationUrls?: Record<string, string>;
}

export const DataContext: Context<DataContextProps> = React.createContext({});

function Layout<T extends { locale: string }>({
  children,
  menu,
  footer,
  socialLinks,
  contactData,
  modals,
  translations,
  seo,
  announcementBar,
  settings,
  localizations,
}: DataProps<T>) {
  const { isMobile } = useIsMobile();

  return (
    <DataContext.Provider
      value={{
        modals: modals.nodes,
        translations: parseTranslations(translations?.items),
        settings,
        localizationUrls: localizations
          ? parseLocalizationUrls<T>(
              [{ attributes: localizations.current }, ...localizations.data],
              localizations.getLocalizationUrl
            )
          : {},
      }}
    >
      <CanonicalLink />
      <HreflangLink />
      <SEO {...seo} />
      {!isMobile && <AnnouncementBar {...announcementBar} />}
      {menu && <Menu menu={menu} />}
      <main className="overflow-x-clip overflow-y-visible">{children}</main>
      {footer && (
        <Footer
          {...{
            ...footer,
            contact: contactData?.items,
            socialLinks: socialLinks,
          }}
        />
      )}
      {isMobile && <AnnouncementBar {...announcementBar} />}
      <CookieConsentComponent buttonText="OK" />
    </DataContext.Provider>
  );
}

export default Layout;
