/* eslint-disable @typescript-eslint/no-explicit-any */
// USED FOR CLINICS, DISEASES, THERAPIES PAGES

import convertToSlug from './convert-to-slug';

export const getTableOfContents = (content: any) => {
  const headers = content.map((item: any) => {
    if (
      item?.content?.heading?.title &&
      item?.content?.heading?.title.split(' ').join('').length > 0
    ) {
      return item.content.heading.title;
    }
    if (
      item?.extras?.title &&
      item?.extras?.title.split(' ').join('').length > 0
    ) {
      return item?.extras?.title;
    }
    if (
      item?.heading?.title &&
      item?.heading?.title.split(' ').join('').length > 0
    ) {
      return item?.heading?.title;
    }
    return null; // Add a return statement for the default case
  });
  if (!headers || !headers.length) return null;
  const filteredHeaders = headers.filter((header: any) => header);

  const tableOfContents = filteredHeaders.map((header: any) => ({
    title: header,
    url: convertToSlug(header),
  }));
  return tableOfContents;
};

export const getTherapyTableOfContents = (
  content: any,
  content2: any,
  cards: any,
  exampleTherapy: any,
  howCanWeHelp: any,
  stats: any,
  faq: any,
  teamMembers: any,
  cta: any
) => {
  if (!content && !content2) return null;

  const headers = content?.map((item: any) => {
    if (
      item?.heading?.title &&
      item?.heading?.title.split(' ').join('').length > 0
    ) {
      return {
        title: item.heading.title,
        items: item?.content?.data?.childMdx?.tableOfContents?.items || null,
      };
    }
    return null; // Add a return statement for the default case
  });

  const headers2 = content2?.map((item: any) => {
    if (
      item?.heading?.title &&
      item?.heading?.title.split(' ').join('').length > 0
    ) {
      return {
        title: item.heading.title,
        items: item?.content?.data?.childMdx?.tableOfContents?.items || null,
      };
    }
    return null; // Add a return statement for the default case
  });

  const restContentHeaders = [
    {
      title: cards?.heading?.title || null,
    },
    {
      title: exampleTherapy?.title || null,
    },
    {
      title: howCanWeHelp?.heading?.title || null,
    },
    {
      title: stats?.heading?.title || null,
    },
  ];

  // const cardsHeaders = cards?.map((item: any) => {
  //   if (
  //     item?.heading?.title &&
  //     item?.heading?.title.split(' ').join('').length > 0
  //   ) {
  //     return {
  //       title: item.heading.title,
  //       items: item?.content?.data?.childMdx?.tableOfContents?.items || null,
  //     }
  //   }
  //   return null; // Add a return statement for the default case
  // });

  const filteredHeaders = headers.filter((header: any) => header);
  const filteredHeaders2 = headers2.filter((header: any) => header);
  const filteredRestHeaders = restContentHeaders.filter(
    (header: any) => header?.title
  );

  const mergedHeaders = [
    ...filteredHeaders,
    ...filteredRestHeaders,
    ...filteredHeaders2,
    {
      title: faq?.heading?.title || null,
    },
    {
      title: teamMembers?.heading?.title || null,
    },
    {
      title: cta?.heading?.title || null,
    },
  ];

  const tableOfContents = mergedHeaders.map((header: any) => ({
    title: header.title,
    url: convertToSlug(header.title),
    items: header.items,
  }));

  return tableOfContents;
};
