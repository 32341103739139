import React from 'react';
import { useTranslationsContextData } from '../../hooks/use-context-data';
import { useIsServerClient } from '../../hooks/use-is-server-client';
import { ImageProps, LightMDXProps } from '../../types/shared';
import { Button } from '../button/button';
import { MDXComponent } from '../shared/mdx-component';
import { ButtonDataProps } from '../../types/shared';
import { Map } from './map';
import { MapContext, MapContextProvider } from './map-context';
import Carousel from '../carousel/carousel';

export interface DrivingDirectionsProps {
  cardId: string;
  title: string;
  text: string;
  subtitle: LightMDXProps;
  link: ButtonDataProps;
  photos: ImageProps[];
}

const DirectionsButton = ({ address, button }) => {
  const { isLoading, getDirections } = React.useContext(MapContext);
  const translations = useTranslationsContextData();

  const content = isLoading
    ? translations['driving-directions.calcDirection']
    : button?.text;

  return (
    <>
      {/* <Button
        className="hidden lg:inline-block mt-14"
        type="secondary"
        full
        onClick={getDirections}
        disabled={isLoading}
      >
        {content}
      </Button> */}
      <Button
        className="lg:hidden mt-14"
        tagName="a"
        type="secondary"
        full
        href={button?.url}
        target="_blank"
        rel="noreferrer"
      >
        {content}
      </Button>
    </>
  );
};

export const DrivingDirections: React.FC<DrivingDirectionsProps> = ({
  cardId,
  title: city,
  text: address,
  subtitle: directions,
  link,
  photos=[],
}) => {
  const { isClient } = useIsServerClient();

  return (
    <MapContextProvider>
      <div
        id={cardId}
        className="grid lg:grid-cols-[9fr_11fr] shadow-lg rounded-2xl overflow-hidden"
      >
        <div className="bg-white p-6">
          <div className="label-l text-gray-900">{city}</div>
          <p className="pt-9 label-m text-gray-600">{address}</p>
          <div className="pt-4 body-s-auto text-gray-500">
            <MDXComponent>{directions?.childMdx?.body}</MDXComponent>
          </div>
          {isClient && <DirectionsButton address={address} button={link} />}
        </div>
        <div className=" block">
          {isClient && address && (
            <Carousel
              className="min-h-116"
              items={photos?.length ? [<Map address={address} />, ...photos] : [<Map address={address} />]}
            />
          )}
          {/* {isClient && <Map address={address} />} */}
        </div>
      </div>
    </MapContextProvider>
  );
};
