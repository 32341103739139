exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-payment-callback-tsx": () => import("./../../../src/pages/payment-callback.tsx" /* webpackChunkName: "component---src-pages-payment-callback-tsx" */),
  "component---src-pages-strapi-blog-category-pathname-strapi-blog-category-url-tsx": () => import("./../../../src/pages/{strapiBlogCategory.pathname}/{strapiBlogCategory.url}.tsx" /* webpackChunkName: "component---src-pages-strapi-blog-category-pathname-strapi-blog-category-url-tsx" */),
  "component---src-pages-strapi-blog-pathname-strapi-blog-slug-tsx": () => import("./../../../src/pages/{strapiBlog.pathname}/{strapiBlog.slug}.tsx" /* webpackChunkName: "component---src-pages-strapi-blog-pathname-strapi-blog-slug-tsx" */),
  "component---src-pages-strapi-clinic-pathname-strapi-clinic-slug-tsx": () => import("./../../../src/pages/{strapiClinic.pathname}/{strapiClinic.slug}.tsx" /* webpackChunkName: "component---src-pages-strapi-clinic-pathname-strapi-clinic-slug-tsx" */),
  "component---src-pages-strapi-contact-page-pathname-strapi-contact-page-slug-tsx": () => import("./../../../src/pages/{strapiContactPage.pathname}/{strapiContactPage.slug}.tsx" /* webpackChunkName: "component---src-pages-strapi-contact-page-pathname-strapi-contact-page-slug-tsx" */),
  "component---src-pages-strapi-contact-page-pathname-tsx": () => import("./../../../src/pages/{strapiContactPage.pathname}.tsx" /* webpackChunkName: "component---src-pages-strapi-contact-page-pathname-tsx" */),
  "component---src-pages-strapi-disease-pathname-strapi-disease-slug-tsx": () => import("./../../../src/pages/{strapiDisease.pathname}/{strapiDisease.slug}.tsx" /* webpackChunkName: "component---src-pages-strapi-disease-pathname-strapi-disease-slug-tsx" */),
  "component---src-pages-strapi-static-content-slug-tsx": () => import("./../../../src/pages/{strapiStaticContent.slug}.tsx" /* webpackChunkName: "component---src-pages-strapi-static-content-slug-tsx" */),
  "component---src-pages-strapi-team-member-pathname-strapi-team-member-slug-tsx": () => import("./../../../src/pages/{strapiTeamMember.pathname}/{strapiTeamMember.slug}.tsx" /* webpackChunkName: "component---src-pages-strapi-team-member-pathname-strapi-team-member-slug-tsx" */),
  "component---src-pages-strapi-therapy-pathname-strapi-therapy-slug-tsx": () => import("./../../../src/pages/{strapiTherapy.pathname}/{strapiTherapy.slug}.tsx" /* webpackChunkName: "component---src-pages-strapi-therapy-pathname-strapi-therapy-slug-tsx" */),
  "component---src-pages-strapi-url-about-us-tsx": () => import("./../../../src/pages/{strapiUrl.aboutUs}.tsx" /* webpackChunkName: "component---src-pages-strapi-url-about-us-tsx" */),
  "component---src-pages-strapi-url-blog-tsx": () => import("./../../../src/pages/{strapiUrl.blog}.tsx" /* webpackChunkName: "component---src-pages-strapi-url-blog-tsx" */),
  "component---src-pages-strapi-url-diseases-tsx": () => import("./../../../src/pages/{strapiUrl.diseases}.tsx" /* webpackChunkName: "component---src-pages-strapi-url-diseases-tsx" */),
  "component---src-pages-strapi-url-recruitment-tsx": () => import("./../../../src/pages/{strapiUrl.recruitment}.tsx" /* webpackChunkName: "component---src-pages-strapi-url-recruitment-tsx" */),
  "component---src-pages-strapi-url-services-tsx": () => import("./../../../src/pages/{strapiUrl.services}.tsx" /* webpackChunkName: "component---src-pages-strapi-url-services-tsx" */)
}

