import cx from 'classnames';
import { changeLocale, useIntl } from 'gatsby-plugin-react-intl';
import React, { useCallback } from 'react';
import { GATSBY_CONFIG_INTL } from '../../../gatsby-config-intl';

import { Menu, Transition } from '@headlessui/react';
import { ReactComponent as PlIcon } from '../../assets/svg/pl.svg';
import { ReactComponent as EnIcon } from '../../assets/svg/en.svg';
import { useLocalizationsContextData } from '../../hooks/use-context-data';

const languagesList = {
  pl: {
    name: 'Polski',
    Icon: PlIcon,
  },
  en: {
    name: 'English',
    Icon: EnIcon,
  },
};

const getFullPath = (lang: string, pathname: string) => {
  const hostAddress =
    lang === 'pl' ? 'https://magwise.pl' : 'https://magwise.org';
  return hostAddress + pathname;
};

const LanguageItem = ({ Icon, name, withLangName }) => (
  <>
    <div className="h-6 w-6 rounded-full overflow-hidden border-solid border-slate-200 border">
      <Icon className="relative h-full left-1/2 -translate-x-5/10" />{' '}
    </div>
    {withLangName && name}
  </>
);

export const LanguageSelect: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { locale } = useIntl();
  // const { languages } = GATSBY_CONFIG_INTL;
  const languages = ['pl', 'en'];
  const localizations = useLocalizationsContextData();

  const getPath = useCallback((lang) => localizations[lang] || '/', []);

  const currentLanguage = React.useMemo(() => languagesList[locale], [locale]);

  return (
    <Menu as="div" className={cx('relative', className)}>
      {currentLanguage && (
        <Menu.Button
          className="justify-center flex items-center
         menu-links-16 font-semibold uppercase gap-4"
        >
          <LanguageItem {...currentLanguage} />
        </Menu.Button>
      )}
      <Transition
        as={React.Fragment}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className="absolute right-0 z-1 max-w-xs w-48 rounded-lg overflow-hidden shadow-lg mt-4 border border-slate-100">
          {languages.map((language) => (
            <Menu.Item key={language}>
              {() => (
                <div
                  className="flex items-center menu-links-16 bg-white hover:bg-gray-100"
                  key={language}
                >
                  <a
                    href={getFullPath(language, getPath(language))}
                    className={cx('h-full w-full p-4 uppercase flex gap-4', {
                      'active text-green': locale === language,
                    })}
                  >
                    <LanguageItem withLangName {...languagesList[language]} />
                  </a>
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default LanguageSelect;
