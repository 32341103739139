import React, { useCallback, useMemo } from 'react';
import Helmet from 'react-helmet';
import { useLocalizationsContextData } from '../../hooks/use-context-data';
import { useLocation } from '@reach/router';

const expression = /[ąćęłńóśźż]/gi;

const replacements = {
  ą: 'a',
  ć: 'c',
  ę: 'e',
  ł: 'l',
  ń: 'n',
  ó: 'o',
  ś: 's',
  ź: 'z',
  ż: 'z',
  Ą: 'A',
  Ć: 'C',
  Ę: 'E',
  Ł: 'L',
  Ń: 'N',
  Ó: 'O',
  Ś: 'S',
  Ź: 'Z',
  Ż: 'Z',
};

const replacePolishLetters = (text) => {
  if (!text) return text;
  return text.replace(expression, (letter) => replacements[letter]);
};

const HreflangLink = () => {
  const { pathname } = useLocation();
  const localizations = useLocalizationsContextData();

  const paths = useMemo(() => {
    return {
      pl: pathname === '/' ? '/' : replacePolishLetters(localizations['pl']),
      en: pathname === '/' ? '/' : replacePolishLetters(localizations['en']),
    };
  }, []);

  if ((!localizations['pl'] || !localizations['en']) && pathname !== '/')
    return null;

  return (
    <Helmet>
      {paths?.pl ? (
        <link
          rel="alternate"
          href={`https://magwise.pl${paths.pl}${
            paths.pl.endsWith('/') ? '' : '/'
          }`}
          hrefLang={'pl'}
        />
      ) : null}
      {paths?.en ? (
        <link
          rel="alternate"
          href={`https://magwise.org${paths.en}${
            paths.en.endsWith('/') ? '' : '/'
          }`}
          hrefLang={'en'}
        />
      ) : null}
      {paths?.en ? (
        <link
          rel="alternate"
          href={`https://magwise.org${paths.en}${
            paths.en.endsWith('/') ? '' : '/'
          }`}
          hrefLang={'x-default'}
        />
      ) : null}
    </Helmet>
  );
};

export default HreflangLink;
