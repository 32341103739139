import React from 'react';
import Helmet from 'react-helmet';
import { useLocation } from '@reach/router';
import { useIntl } from 'gatsby-plugin-react-intl';

const CanonicalLink = () => {
  const { pathname, hash, search } = useLocation();
  const { locale } = useIntl();

  return (
    <Helmet>
      {pathname.length && (hash.length || search.length) ? (
        <meta name="robots" content="noindex"></meta>
      ) : locale === 'en' &&
        process.env.GATSBY_APP_URL === 'https://magwise.pl' ? (
        <link
          rel="canonical"
          href={`https://magwise.org${pathname.slice(3)}`}
        />
      ) : (
        <link
          rel="canonical"
          href={`${process.env.GATSBY_APP_URL}${pathname}`}
        />
      )}
    </Helmet>
  );
};

export default CanonicalLink;
