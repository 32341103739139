import { graphql } from 'gatsby';
import Contact from './{strapiContactPage.pathname}/{strapiContactPage.slug}';

export default Contact;

export const query = graphql`
  query ContactSinglePage($language: String) {
    modals: allStrapiModalType(filter: { locale: { eq: $language } }) {
      nodes {
        ...ModalTypeFragment
      }
    }
    translations: strapiTranslation(locale: { eq: $language }) {
      ...TranslationsFragment
    }
    settings: strapiSetting(locale: { eq: $language }) {
      ...SettingsFragment
    }
    menu: strapiMenu(locale: { eq: $language }) {
      ...MenuFragment
    }
    footer: strapiFooter(locale: { eq: $language }) {
      ...FooterFragment
    }
    socialLinks: strapiSocialLink(locale: { eq: $language }) {
      ...SocialLinksFragment
    }
    contactData: strapiContactData(locale: { eq: $language }) {
      ...ContactDataFragment
    }
    announcementBar: strapiAnnouncementBar(locale: { eq: $language }) {
      ...AnnouncementBarFragment
    }
    urls: strapiUrl(locale: { eq: $language }) {
      ...UrlsFragment
    }
    contact: strapiContactPage(
      locale: { eq: $language }
      slug: { in: ["warsaw/", "warszawa/"] }
    ) {
      locale
      pathname
      slug
      localizations {
        data {
          attributes {
            locale
            pathname
            slug
          }
        }
      }
      seo {
        ...SeoFragment
      }
      heading {
        ...HeadingAllFragment
      }
      mainphoto {
        ...ImageFragment
      }
      transferData {
        label
        text {
          data {
            childMdx {
              body
            }
          }
        }
      }
      clinics {
        anchorId
        heading {
          ...HeadingTitleDescriptionFragment
        }
        cards {
          cardId
          ...CardFragment
        }
      }
      parkingMap {
        id: anchorId
        heading {
          ...HeadingTitleDescriptionFragment
        }
        image {
          ...ImageFragment
        }
      }
      cooperatingCenters {
        id: anchorId
        heading {
          ...HeadingTitleDescriptionFragment
        }
        cards {
          ...CardFragment
          image {
            ...ImageFragment
          }
        }
      }
    }
  }
`;
