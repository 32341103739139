import React, { memo, useState, useEffect, useRef, useCallback } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import cx from 'classnames';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items?: any[];
  autoPlay?: boolean;
  autoPlayInterval?: number;
  className?: string;
}

function Carousel({
  items,
  autoPlay = false,
  autoPlayInterval = 5000,
  className,
}: IProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const carouselRef = useRef<HTMLDivElement>(null);

  const handlePrev = useCallback(() => {
    if (isAnimating) return;
    setIsAnimating(true);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
    setTimeout(() => setIsAnimating(false), 500); // Matches transition duration
  }, [isAnimating, items?.length]);

  const handleNext = useCallback(() => {
    if (isAnimating) return;
    setIsAnimating(true);
    setCurrentIndex((prevIndex) =>
      prevIndex === (items?.length || 0) - 1 ? 0 : prevIndex + 1
    );
    setTimeout(() => setIsAnimating(false), 500); // Matches transition duration
  }, [isAnimating, items?.length]);

  // Auto-play functionality
  useEffect(() => {
    if (!autoPlay) return;

    const interval = setInterval(() => {
      handleNext();
    }, autoPlayInterval);

    return () => clearInterval(interval);
  }, [autoPlay, autoPlayInterval, handleNext]);

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <div
      className={cx(
        'relative w-full h-full overflow-hidden rounded-lg',
        className
      )}
      ref={carouselRef}
    >
      {/* Slides container */}
      <div
        className="h-full flex transition-transform duration-500 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {items.map((item, index) => {
          const imageData = getImage(
            item?.localFile?.childImageSharp
              ?.gatsbyImageData as IGatsbyImageData
          );

          if (!imageData && !item) return null;

          return (
            <div
              key={index}
              className="flex-shrink-0 w-full h-full"
              aria-hidden={index !== currentIndex}
            >
              {imageData ? (
                <GatsbyImage
                  image={imageData}
                  alt={`Slide ${index + 1}`}
                  className="w-full h-full object-cover"
                />
              ) : (
                item
              )}
            </div>
          );
        })}
      </div>

      {/* Navigation buttons */}
      <button
        className="absolute left-4 top-1/2 -translate-y-1/2 border bg-white p-2 rounded-full z-10 transition-all"
        onClick={handlePrev}
        disabled={isAnimating}
        aria-label="Previous slide"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
      </button>

      <button
        className="absolute right-4 top-1/2 -translate-y-1/2 border bg-white p-2 rounded-full z-10 transition-all"
        onClick={handleNext}
        disabled={isAnimating}
        aria-label="Next slide"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
      </button>

      {/* Indicators */}
      <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2 z-50">
        {items.map((_, index) => (
          <button
            key={index}
            className={`w-2 h-2 rounded-full transition-all border border-black ${
              index === currentIndex ? 'bg-white scale-125' : 'bg-white/50'
            }`}
            onClick={() => {
              if (isAnimating) return;
              setIsAnimating(true);
              setCurrentIndex(index);
              setTimeout(() => setIsAnimating(false), 500);
            }}
            aria-label={`Go to slide ${index + 1}`}
            aria-current={index === currentIndex ? 'true' : 'false'}
          />
        ))}
      </div>
    </div>
  );
}

export default memo(Carousel);
