import React from 'react';
import { ReactComponent as Facebook } from '../../assets/svg/facebook.svg';
import { ReactComponent as Linkedin } from '../../assets/svg/linkedin.svg';
import { ReactComponent as ZnanyLekarz } from '../../assets/svg/znanylekarz.svg';
import { ImageProps, SocialLinksProps } from '../../types/shared';
import { ContactItem, ContactItemProps } from './contact-item';
import { GatsbyImage } from 'gatsby-plugin-image';

export interface ContactProps {
  items: ContactItemProps[];
  socialLinks: SocialLinksProps;
  description?: string;
  image?: ImageProps;
}

export const Contact: React.FC<ContactProps> = ({
  items,
  socialLinks,
  description,
  image,
}) => (
  <div className="flex flex-col items-center gap-8">
    {items.map((contact) => (
      <ContactItem key={contact.label} {...contact} />
    ))}
    {description ? (
      image ? (
        <div className="flex flex-col gap-8 max-w-lg mx-auto ">
          <div className={'body-s text-gray-500 flex-1 text-center'}>
            {description}
          </div>
          <div className="relative  h-full flex items-center">
            <GatsbyImage
              className="mx-auto rounded-lg max-h-[200px] w-[170px]"
              image={image?.localFile.childImageSharp.gatsbyImageData}
              objectFit="cover"
              objectPosition="50% 20%"
              alt=""
            />
          </div>
        </div>
      ) : null
    ) : null}
    <div className="flex gap-5">
      {socialLinks?.facebook && (
        <a href={socialLinks.facebook} target="_blank" rel="noreferrer">
          <Facebook className="fill-brand-facebook w-9 h-9" />
        </a>
      )}
      {socialLinks?.linkedin && (
        <a href={socialLinks.linkedin} target="_blank" rel="noreferrer">
          <Linkedin className="fill-brand-linkedin w-9 h-9" />
        </a>
      )}
      {socialLinks?.znanyLekarz && (
        <a href={socialLinks.znanyLekarz} target="_blank" rel="noreferrer">
          <ZnanyLekarz className="fill-brand-znany-lekarz w-9 h-9" />
        </a>
      )}
    </div>
  </div>
);
